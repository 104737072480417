angular.module('app')
    .factory('staffService', ["$rootScope", "$log", "$http", "$q", "authenticatedStatus", "currentOrganisation", function ($rootScope, $log, $http, $q, authenticatedStatus, currentOrganisation) {
        // Correspond to Thundr Routes - defined for Controllers
        var urls = {
            list: '/staff/',
            add: function (organisationReference) {
                return sprintf('/staff/%s', organisationReference);
            },
            update: function (organisationReference, staffReference) {
                return sprintf('/staff/%s/%s', organisationReference, staffReference);
            },
            get: function (organisationReference, accountReference) {
                return sprintf("/staff/%s/%s", organisationReference, accountReference);
            },
            removeStaff: function (organisationReference, staff) {
                return sprintf("/%s/staff/%s", organisationReference, staff);
            }
        };

        function normalise(staff) {
            staff.searchable = sprintf("%s %s %s", staff.name, staff.mobileNumber, staff.emailAddress);
            if (staff.active === undefined) {
                // TODO - NAO - this is an interim hack till we figure out how this information is sent from the API
                staff.active = true;
                staff.displayName = sprintf("%s (%s)", staff.name, staff.mobileNumber);
            }
            return staff;
        }

        function normaliseStaffPaymentMethods(staff) {
            var cards = _.filter(staff.paymentMethodInfo,
                function (paymentMethod) {
                    return paymentMethod.type === 'Card';
                }
            );

            var accounts = _.filter(staff.paymentMethodInfo,
                function (paymentMethod) {
                    return paymentMethod.type === 'Account';
                }
            );

            _.each(cards, function (card) {
                card.name = card.nickname;
                card.displayType = "Card";
                card.number = card.cardNumber;
            });

            _.each(accounts, function (account) {
                account.name = account.accountName;
                account.organisationPaymentMethodReference = account.accountReference;
                account.displayType = "Account";
            });

            staff.paymentMethods = accounts.concat(cards);
            return staff;
        }

        var api = {
            list: function (params) {
                $log.info("Listing staff");
                return currentOrganisation.getOrganisationReference().then(function (reference) {
                    params.organisationReference = reference;
                    return $http.get(urls.list, {params: params})
                        .then(function (resp) {
                            resp.data.results = _.map(resp.data.results, normalise);
                            return resp.data;
                        });
                });
            },

            listByName: function (params) {
                $log.info("Listing staff");
                return $http.get(urls.list, {params: params})
                    .then(function (resp) {
                        resp.data.results = _.map(resp.data.results, normalise);
                        return resp.data;
                    });
            },
            get: function (organisationReference, staffReference) {
                $log.info("Get staff");
                return $http.get(urls.get(organisationReference, staffReference))
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            add: function (staff) {
                return currentOrganisation.getOrganisationReference().then(function (reference) {
                    return $http.post(urls.add(reference), staff)
                        .then(function (resp) {
                            return resp.data;
                        });
                });
            },
            update: function (organisationReference, staffReference, staff) {
                return $http.put(urls.update(organisationReference, staffReference), staff)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            removeStaff: function (organisationReference, staffReference) {
                return $http.delete(urls.removeStaff(organisationReference, staffReference))
                    .then(function (resp) {
                        alert("Staff reference " + staffReference + " has been removed");
                        return;
                    });
            }
        };

        return api;
    }])
;
